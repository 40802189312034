<template>
  <div id="login" style="min-height: calc(100vh - 20px);" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh;">
            <b-col class="d-flex justify-content-center align-items-center">
              <div style="width: 566px;">
                <div v-if="verify_page === 'email_duplicate'" class="text-center">
                  <p class="header-text font-size-24">
                    {{ $t('account.verify_email.verify_already') }}
                  </p>
                  <span class="header-text font-size-14 p-0">{{ $t('account.verify_email.verify_success_thank') }}</span>
                  <!-- <p class="header-text font-size-14">SMS2PRO</p> -->
                </div>
                
                <p v-if="verify_page !== 'email_duplicate'" class="header-text font-size-24">{{ $t('verifyEmail.header') }}</p>
                <p v-if="verify_page === 'email_confirm'" class="header-text font-size-14">
                  {{ $t('verifyEmail.note1') }}<br>{{ $t('verifyEmail.note2') }}
                </p>
                <!-- กรณีกส่งสำเร็จ word = ระบบได้ส่งลิ้งก์ยืนยันการเข้าใช้งานระบบ -->
                <p v-if="verify_page === 'email_success'" class="header-text font-size-14">
                  <span v-if="timeRemaining > 0">{{ $t('account.verify_email.verify_email_text') }}
                    <br>{{ $t('account.verify_email.verify_email_confirm') }}
                  </span>
                  <span v-else>{{ $t('account.verify_email.verify_email_timeout') }}</span>
                </p>
                <!-- กรณีการส่งล้มเหลว word = ลิ้งก์ยืนยันล้มเหลว กรุณาตรวจสอบความถูกต้องของอีเมล -->
                <p v-if="verify_page === 'email_fail'" class="header-text font-size-14">
                  {{ $t('account.verify_email.send_link_fail') }}
                </p>
                <!-- กรณีแก้ไขอีเมล word = แก้ไขอีเมลของคุณ -->
                <p v-if="verify_page === 'email_update'" class="header-text font-size-14">
                  {{ isReqEmail ?  $t('account.verify_email.system_sending') : $t('account.verify_email.edit_email') }}
                </p>
                <div v-if="verify_page !== 'email_duplicate'">
                  <b-form-input
                  v-model="email"
                  :placeholder="$t('verifyEmail.placeholder')"
                  class="input-box mb-2"
                  :disabled="!isUpdateEmail"
                  >
                  </b-form-input>
                </div>
                
                <div v-if="verify_page !== 'email_duplicate'" class="d-flex justify-content-between input-box" style="height: 30px">
                  <div >
                    <span v-if="email && !isValidEmail && !isEmailSameAsQuery && !duplicateEmail" class="text-danger">{{ $t('verifyEmail.invalid') }}</span>
                    <span v-else-if="isEmailSameAsQuery && isUpdateEmail" class="text-danger">{{ $t('verifyEmail.duplicate') }}</span>
                    <span v-else-if="duplicateEmail && !isUpdateEmail" class="text-danger">{{ $t('verifyEmail.systemDuplicate') }}</span>
                  </div>
                  <span
                    v-if="!isUpdateEmail && timeRemaining <= 0"
                    class="edit-txt" 
                    @click="toggleEmailEdit">
                    {{ $t('verifyEmail.editEmail') }}
                  </span>
                </div>
                <!-- หน้าอัพเดทอีเมล -->
                <div v-if="verify_page === 'email_update'" class="btn-sector d-flex justify-content-between mt-3">
                  <b-button
                    class="button-wizard"
                    variant="outline"
                    :disabled="isReqEmail"
                    @click="backToConfirm"
                  >
                  <i class="fas fa-angle-left"/>
                    {{ $t('common.previous') }}
                  </b-button>
                  <b-button
                    class="btn-confirm px-5"
                    @click="toggleEmailPage('update')"
                    :disabled="!isValidEmail || isEmailSameAsQuery || isReqEmail"
                  >
                    {{ $t('verifyEmail.updateEmail') }}
                    <b-spinner small v-if="isReqEmail"/>
                  </b-button>
                </div>
                <div v-else-if="verify_page === 'email_duplicate'" class="d-flex justify-content-center">
                  <b-button
                      class="btn-back px-4"
                      :disabled="isReqEmail"
                      @click="backToLogin"
                    >
                      {{ $t('verifyEmail.backToLogin') }}
                    </b-button>
                </div>
                <!-- หน้ายืนยันอีเมล -->
                <div v-else class="btn-sector d-flex justify-content-between mt-3">
                  <div >
                  <!-- ปุ่มก่อนหน้า -->
                    <b-button
                    v-if="verify_page === 'email_success'"
                    class="button-wizard"
                    variant="outline"
                    :disabled="isReqEmail"
                    @click="verify_page = 'email_confirm'"
                  >
                  <i class="fas fa-angle-left"/>
                    {{ $t('common.previous') }}
                  </b-button>
                  <!-- ปุ่มกลับสู่หน้าล็อคอิน -->
                    <b-button
                      v-else
                      class="btn-back px-4"
                      :disabled="isReqEmail"
                      @click="backToLogin"
                    >
                      {{ $t('verifyEmail.backToLogin') }}
                    </b-button>
                  </div>
                  <div>
                    <!-- ยืนยันอีเมล -->
                    <b-button
                      v-if="verify_page === 'email_confirm'"
                      class="btn-confirm px-5"
                      @click="toggleEmailPage('confirm')"
                      :disabled = "!isValidEmail || isReqEmail"
                    >
                      {{ $t('verifyEmail.confirmEmail') }}
                      <b-spinner small v-if="isReqEmail"/>
                    </b-button>
                    <!-- ส่งลิ้งก์อีกครั้ง -->
                    <b-button
                      v-else-if="verify_page === 'email_fail'"
                      class="btn-confirm px-5"
                      @click="verifyEmail"
                      :disabled = "!isValidEmail || isReqEmail"
                    >
                      {{ $t('account.verify_email.new_req_email') }}
                      <b-spinner small v-if="isReqEmail"/>
                    </b-button>
                    <!-- ส่งลิ้งก์อีกครั้ง (01:00) -->
                    <b-button 
                      v-else
                      class="btn-confirm px-5"
                      @click="verifyEmail"
                      :disabled = "timeRemaining > 0 || isReqEmail"
                    >
                      {{ timeRemaining > 0 ? $t('account.verify_email.new_req_email') + " (" + timerShower + ")" 
                                           : $t('account.verify_email.new_req_email') 
                      }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import functionCommon from "@/common/functions";
import profileApi from "@/repository/profileApi";

export default {
  name: "verifyEmailNewUser",
  data() {
    return {
      email: "",
      queryEmail: "",
      isUpdateEmail: false,
      duplicateEmail: false,
      isValidEmail: false,
      isReqEmail: false,
      verify_page: 'email_confirm',
      timeRemaining: 0,
      intervalId: null,
      timerShower: this.formatTime(60),
    };
  },
  created() {
    const lang = sessionStorage.getItem('lang') || this.getLanguageProfile;
    this.$i18n.locale = this.$route.query.locale ? this.$route.query.locale : lang ? lang : 'en';
    // Get email from query string and set it to email data property
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    if (email) {
      this.email = email;
      this.queryEmail = email;
    }
  },
  computed: {
    isEmailSameAsQuery() {
      return this.email === this.queryEmail;
    },
  },
  watch: {
    email: 'validateEmail'
  },
  methods: {
    // Navigate back to the login page
    backToLogin() {
      this.$router.push('Login').catch(() => {});
    },
    backToConfirm() {
      this.isUpdateEmail = !this.isUpdateEmail;
      this.isValidEmail = true
      this.verify_page = 'email_confirm'
    },
    toggleEmailEdit() {
      if(this.isReqEmail){
        return
      }
      this.isUpdateEmail = !this.isUpdateEmail;
      this.verify_page = 'email_update'
      this.duplicateEmail = false
    },
    toggleEmailPage(type) {
      if(type === 'update'){
        this.verify_page = 'email_update'
        this.isUpdateEmail = !this.isUpdateEmail;
        this.verifyEmail()
      }
      else {
        this.verifyEmail()
      }
    },
    // Check if the input contains only English characters
    checkIsNotThai(e) {
      return functionCommon.isOnlyEnglish(e);
    },
    // Validate the email format and check for duplicates
    async validateEmail() {
      if (this.email === "") {
        this.isValidEmail = false;
        return;
      }
      if (!this.isUpdateEmail) {
        this.isValidEmail = true;
        return;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(this.email)) {
        // await this.checkEmailDuplicate();
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
    // Check if the email is already in use
    async checkEmailDuplicate() {
      let lower = this.email.toLowerCase()
      const data = {
        type: "email",
        value: lower.trim()
      }
      try {
        const response = await profileApi.checkDuplicate(data);
        if (response.codeSYS === '001' && !response.success) {
          this.duplicateEmail = true;
        } else {
          this.duplicateEmail = false;
        }
      } catch (error) {
        this.duplicateEmail = false;
      }
    },
    startTimer() {
      this.timeRemaining = 60;
      this.timerShower = this.formatTime(this.timeRemaining);
      this.intervalId = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining <= 0) {
          clearInterval(this.intervalId);
        }
        this.timerShower = this.formatTime(this.timeRemaining);
      }, 1000);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    },
    async verifyEmail() {
      this.isReqEmail = true
      const lower = this.email.toLowerCase()
      const token = localStorage.getItem('token')
      const data = {
        email: lower,
        token: token
      }
      try {
        const response = await profileApi.requestEmailOTP(data);
        if (response.data?.codeSYS === '001') {
          this.queryEmail = this.email;
          const lang = sessionStorage.getItem('lang') || this.getLanguageProfile;
          localStorage.setItem("lang", lang);
          this.verify_page = 'email_success'
          this.duplicateEmail = false
          this.startTimer()
        } else if (response.data?.codeSYS === '428') {
          this.verify_page = 'email_duplicate'
          this.duplicateEmail = false;
        } else if (response.data?.codeSYS === '301') {
          this.verify_page = 'email_fail'
          this.duplicateEmail = true
        } else{
          this.verify_page = 'email_fail'
          this.duplicateEmail = false;
        } 
      } catch (error) {
        this.verify_page = 'email_fail'
        this.duplicateEmail = false;
      } finally {
        this.isReqEmail = false
      }
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 100%;
}
.edit-txt {
  cursor: pointer;
  color: #19CBAC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 250% */
}
.btn-back {
  width: 150px;
  border-radius: 80.519px;
  border: 1px solid #19CBAC;
  background-color: #ffffff !important;
  color: #19CBAC;
  box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.20);
}
.btn-confirm {
  /* width: 150px; */
  border-radius: 80.519px;
  border: 1px solid #19CBAC;
  background-color: #19CBAC !important;
  color: #ffffff;
  box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.20);
}
.btn-confirm.disabled {
  background-color: #D4D4D4 !important;
  border: 1px solid #D4D4D4;
  color: #ffffff;
  cursor: not-allowed;
}
@media (max-width: 540px) {
  .content-wrapper {
    padding: 20px;
  }
  .input-box {
    width: 95%;
    margin: 0 auto;
  }
  .header-text {
    text-align: center;
  }
  .btn-back, .btn-confirm {
    width: 95%;
    margin-bottom: 10px;
  }
  .btn-sector {
    flex-direction: column;
    align-items: center;
  }
}
</style>